<template>
  <v-main>

    <v-container fluid class="error_content fill-height">

        <v-row align="center" justify="center" class="mt-4 pb-4">

          <v-col cols="12" class="text-center">
            <h1>{{currentError}}</h1>
          </v-col>

          <v-col cols="12" class="text-center">
            <h2 class="mt-4">{{displayText}}</h2>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-btn
              text
              outlined
              color="primary"
              class="btn-primary"
              @click="$router.push({name: 'Home'})">
              {{$t('common.errorPages.btnRedirect')}}
            </v-btn>
          </v-col>

        </v-row>

      </v-container>

  </v-main>
</template>

<script>

  const exceptedErrors = ["401", "404", "500"];

  export default
  {
    name: "Error",

    components: {
    },

    data()
    {
      return {
        displayText: "",
        currentError: null
      }
    },

    created()
    {
      if (exceptedErrors.includes(this.$route.params.status))
      {
        this.displayText = this.$t(`common.errorPages.${this.$route.params.status}.text`);
        this.currentError = this.$route.params.status;
      }
      else
        this.$router.push({name: 'error', params: {status: "404"}});

      this.$store.commit("pageHead/setTitle", this.$t('common.errorPages.pageTitle', {status: this.currentError}));
    },

  }
</script>

<style scoped>

  .error_content h1
  {
    font: 700 200px/200px 'MontserratMedium', sans-serif !important;
    text-shadow: 20px 7px rgba(5,5,5,.1) !important;
    background-image: -webkit-linear-gradient(0deg, #4776e6 0%, #8e54e9 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
</style>